<template>
  <div class="pc:mt-[26px] mobile:mt-[13px] flex mobile:flex-col">
    <NuxtLink to="/register"
              class="login-btn pc:mr-[12px] text-osn-black-00 bg-osn-yellow-00">
      {{ $t('footer.user_register') }}
    </NuxtLink>
    <NuxtLink to="/host/plan"
              class="pc:mr-[12px] login-btn text-osn-yellow-00 bg-osn-dark-grey-00"
              @click="goToBackStage">
      {{ $t('footer.owner_plan') }}
    </NuxtLink>
    <NuxtLink to="/host/homepage"
              class="login-btn text-osn-black-00 bg-osn-white-00"
              @click="goToBackStage">
      {{ $t('footer.owner_function') }}
    </NuxtLink>
  </div>
</template>
<script setup>
import {useGoTo} from "@/composables/useGoTo";
import {ref} from "vue";

const {$fbq} = useNuxtApp()

function goToBackStage() {
  $fbq('trackCustom', 'OSHostPotentialCustomers')
}
</script>
<style scoped
       lang="scss">
.login-btn {
  @apply text-[15px] font-semibold rounded-full cursor-pointer
  pc:px-[12px] pc:h-[50px] pc:leading-[50px]
  mobile:mb-2 mobile:w-[187px] mobile:h-[40px] mobile:leading-[40px]  mobile:text-center;
}

</style>
